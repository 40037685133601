<template>
    <div class="ad-list">
        <div class="workinfo">
            <div class="title">
                {{item.client}}
            </div>
            <div class="desc">
                <el-dropdown @command="change" >
                    <span class="el-dropdown-link">
                        {{deviceType||'筛选设备类型'}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="item in deviceTypeList" :key="item" :command="item">{{item}}</el-dropdown-item>
                    </el-dropdown-menu>
                    </el-dropdown>
                <span v-html="desc" class="descSpan"></span>
            </div>
        </div>
        <el-table
            border
            fit
            width="100%"
            :data="list">
                <el-table-column
                label="序号"
                type="index"
                :index="indexMethod">
                </el-table-column>
                <el-table-column
                    label="设备名称">
                    <template slot-scope="scope">
                        {{scope.row.name}} #{{scope.row.num}}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="place"
                    label="位置">
                </el-table-column>
                <el-table-column
                    prop="status"
                    label="状态">
                </el-table-column>
                <el-table-column
                    prop="useMaterial"
                    label="耗材是否更换">
                </el-table-column>
                <el-table-column
                    label="虫害数据">
                    <template slot-scope="scope">
                        <span>鼠:{{scope.row.bugs.mouse}}</span>,
                        <span>蟑螂:{{scope.row.bugs.roach}}</span>,
                        <span>苍蝇:{{scope.row.bugs.fly}}</span>,
                        <span>蚊子:{{scope.row.bugs.mozzie}}</span>,
                        <span>蚤蝇/果蝇/蛾蠓:{{scope.row.bugs.otherFly}}</span>,
                        <span>园林昆虫:{{scope.row.bugs.treesFly}}</span>,
                        <span>其它昆虫:{{scope.row.bugs.otherBugs}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注">
                </el-table-column>
                <el-table-column
                    width="220px"
                    label="图片附件">
                    <template slot-scope="scope">
                        <!-- {{scope.row.images}} -->
                        <div class="flex">
                        <Images
                        v-for="item in scope.row.images"
                        :key="item"
                        :preview-src-list="scope.row.images"
                        style="width: 100px; height: 100px"
                        :src="item"
                        fit="fit"></Images>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                width="50px"
                    label="操作">
                    <template slot-scope="scope">
                        <el-link type="info" @click="del(scope.$index)"><i class="el-icon-delete" /></el-link>
                    </template>
                </el-table-column>
        </el-table>
    </div>
</template>
<script>
import Images from '@/components/Image.vue'
import {resetJsonBy} from "@/utils/util.js"
export default {
    name:"LastAdviseList",
    components:{
        Images
    },
    props:{
        data:{
            type:Object
        }
    },
    watch:{
        data:{
            handler(val){
                this.item=val
                this.list=val.deviceList
                console.log(this.list)
                this.getDesc()
            },
            deep:true,
            immediate:true
        }
    },
    data(){
        return{
            deviceType:"",//筛选设备类型
            deviceTypeList:[],
            checked:false,
            item:{},
            list:[],
            desc:"",//行动描述
        }
    },methods:{
        indexMethod(index) {
            return index * 1 + 1;
        },
        del(index){
           console.log(index)
           this.list.splice(index,1)
        },
        change(e){
            this.deviceType= e
            //筛选设备
            let list=[]
            this.list = this.data.deviceList
            this.list.forEach(item=>{
                if(item.name==e){
                    list.push(item)
                }
            })
            this.list=list
        },
        getDesc(){
            let total = this.list.length
            let list = resetJsonBy(this.list,"name"),str='',deviceTypeList=[]
            for(let i in list){
                //console.log(i)
                deviceTypeList.push(list[i].name)
                str+=list[i].name+':'+list[i].data.length+';'
            }
            this.deviceTypeList = deviceTypeList
            this.desc = `共计<font style='font-size:22px' color='#39f'>${total}</font>个设备,${str}`
        }
    }
}
</script>
<style lang="less" scoped>
.el-image{
    margin-right:5px;
}
span{
    font-size: 12px;
}
.descSpan{
    font-size: 16px;
    margin-left: 10px;
}
.title{
    font-size: 26px;
    color: rgb(55, 56, 56);
    font-weight: bold;
    line-height: 36px;
    padding:10px 0;
}
.desc{
    line-height: 30px;
    padding:10px 0;
}
.first{
    color:#666;
}
.sec{
    color:#999;
}
span.done{
    font-size: 30px;
}
.el-icon-success{
    color:rgb(59, 196, 78);
}
.el-icon-error{
    color:rgb(228, 88, 88);
}
</style>