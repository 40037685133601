<template>
     <div class="img">
         <el-image
            :style="{width,height}"
            @load="load"
            :preview-src-list="previewList"
            :src="url"
            crossorigin='anonymous'
            :fit="fit">
            </el-image>
     </div>
</template>
<script>
export default {
    props:{
        'preview-src-list':{
            type:Array
        },
        src:{
            type:String
        },
        width:{
            type:String
        },
        height:{
            type:String
        },
        fit:{
            type:String,
            default:"fit"
        }
    },
    data(){
        return{
            previewList:[],
            url:""
        }
    },
    watch:{
        src:{
            handler(val){
                //console.log(val)
                //this.url="https://oa.yhipm.cn/crossOriginImg?referer="+val
                //this.convertUrlToBase64(val)
                this.url = this.API.url+'utils/http_test.php?url='+encodeURIComponent(val)
                //console.log(this.url)
            },
            deep:true,
            immediate:true
        },
        previewSrcList:{
             handler(val){
                 let list=[]
                val.forEach(e => {
                   list.push(
                       this.API.url+'utils/http_test.php?url='+encodeURIComponent(e) 
                   ) 
                });
                this.previewList = list
            },
            deep:true,
            immediate:true
        }
    },
    methods:{
        load(e){
            console.log("----加载完成")
            console.log(e)
        },
         getBase64(url, callback) {
            var Img = new Image(),
            dataURL = '';
            Img.src = url +"?v=" + Math.random();
            Img.setAttribute("crossOrigin", 'Anonymous') 
            Img.onload = function () { 
                var canvas = document.createElement("canvas"), 
                width = Img.width, 
                height = Img.height;
                canvas.width = width;
                canvas.height = height;
                canvas.getContext("2d").drawImage(Img, 0, 0, width, height); 
                dataURL = canvas.toDataURL('image/jpeg'); 
                callback ? callback(dataURL) : null; 
            };
        },
        // 传入 cdn 地址
        convertUrlToBase64(url) {
            //return new Promise((resolve) => {
                let img = new Image();
                img.crossOrigin = '*';
                img.src = url+ '?time=' + new Date().valueOf();
                img.onload = function () {
                    let canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    let ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, img.width, img.height);
                    let ext = img.src.substring(img.src.lastIndexOf('.') + 1).toLowerCase();
                    let dataURL = canvas.toDataURL('image/' + ext);
                    let base64 = {
                        dataURL: dataURL,
                        type: 'image/' + ext,
                        ext: ext
                    };
                    this.url = base64
                    console.log('huoqucheng----')
                    //resolve(base64)
                }
            //})
        }
    }
}
</script>
<style lang="less" scoped>
.img-box-in{
    background-size: 100% 100%;
}
</style>