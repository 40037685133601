<template>
    <div class="ad-list">
        <div class="workinfo">
            <div class="ac-title">
                <div class="t-title">{{client}}</div>
                <div class="t-right">
                    <el-image
                    style="width: 80px; height: 80px"
                    :src="codeRs"
                    fit="fit"></el-image>
                    <div class="text-center">扫一扫，看报告</div>
                </div>
            </div>
            <el-descriptions title="全面检查" border :column="2">
                <el-descriptions-item :labelStyle="labelStyle" label="服务时间">{{item.start|dateFormat}} {{item.start|timeRangeFormat(item.end)}}</el-descriptions-item>
                <el-descriptions-item label="虫害风险">
                    鼠:<span :class="item.mouseRisk=='R'?'risktype':''">{{item.mouseRisk}}</span>,
                    蟑螂:<span :class="item.roachRisk=='R'?'risktype':''">{{item.roachRisk}}</span>,
                    飞虫:<span :class="item.flyRisk=='R'?'risktype':''">{{item.flyRisk}}</span>,
                    其它:<span :class="item.otherRisk=='R'?'risktype':''">{{item.otherRisk}}</span>
                </el-descriptions-item>
                <el-descriptions-item v-if="showSummary" label="总结">{{data.summary}} <i class="el-icon-remove-outline" @click="showSummary=false"/></el-descriptions-item>
            </el-descriptions>
            <div class="desc">
                <span v-html="desc" class="descSpan"></span>
                <el-checkbox v-model="checked" @change="change">只显示风险项</el-checkbox>
            </div>
        </div>
        <el-table
            border
            fit
            width="100%"
            :row-class-name="tableRowClassName"
            :data="list">
                <el-table-column
                label="序号"
                type="index"
                :index="indexMethod">
                </el-table-column>
                <el-table-column
                    label="区域">
                    <template slot-scope="scope">
                        <span class="first">{{scope.row.firstPlace}}</span> &gt; <span class="sec"> {{scope.row.secondPlace}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="风险类型">
                    <template slot-scope="scope">
                        <span class="first">{{scope.row.risk}}</span> 
                        <span v-if="scope.row.riskDesc!='--'&&scope.row.riskDesc!='无'">
                            &gt; 
                            <span class="first">{{scope.row.riskDesc}}</span>
                            {{scope.row.nums&&scope.row.nums!=='无'?' '+scope.row.nums:""}}
                        </span>
                        
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="描述">
                </el-table-column>
                <el-table-column
                    width="220px"
                    label="图片附件">
                    <template slot-scope="scope">
                        <!-- {{scope.row.images}} -->
                        <div class="flex">
                        <el-image
                        v-for="item in scope.row.images"
                        :key="item"
                        style="width: 100px; height: 100px"
                        :src="item"
                        :preview-src-list="scope.row.images"
                        crossorigin='anonymous'
                        fit="fit"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="!waiting"
                    width="50px"
                    label="操作">
                    <template slot-scope="scope">
                        <el-link type="info" @click="del(scope.$index)"><i class="el-icon-remove-outline" /></el-link>
                    </template>
                </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    name:"LastAdviseList",
    props:{
        data:{
            type:Object
        },
        waiting:{
            type:Boolean
        }
    },
    watch:{
        data:{
            handler(val){
                this.item=val
                //this.list=val.actionList
                this.codeRs = this.API.url+'weixin/?method=getActionList&id='+val.id
                this.client = val.client
                //console.log(this.codeRs)
                this.getDesc()
                this.getMCNum()
            },
            deep:true,
            immediate:true
        }
    },
    filters:{
        filterName(client,num){
            let str = client
            if(client&&num){
                str = num+'-'+client.replace('麦当劳','')
            }
            return str;
        }
    },
    data(){
        return{
            labelStyle:{
                'min-width':"80px"
            },
            client:"",
            clientNum:'',
            showSummary:true,
            info:{},
            checked:false,
            item:{},
            list:[],
            desc:"",//行动描述
            codeRs:"",//二维码
        }
    },methods:{
        //获取客户编号
        getMCNum(){
            let params = {
                method:"getMCNum",
                client:this.item.client
            }
            console.log(params,'params')
            this.loading = true
            this.$ajax.post(this.API.api,params).then(res=>{
                console.log(res,'res------',res.length)
                this.loading=false
                let data = res.data
                if(data.length&&data.length>=1){
                    console.log("-----改变")
                    this.clientNum = data[0].num
                    this.client = this.clientNum+'-'+this.item.client.replace("麦当劳",'')
                }
                this.$forceUpdate()
                //this.list = res.data
            }).catch(err=>{
                //Err
                console.log(err)
                this.loading=false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        },
        tableRowClassName({row}) {
           // console.log(row.risk,row.riskDesc,row.source)
            if(row.riskDesc!='--'&&row.riskDesc!='无'){
                if(this.checked){
                    if(row.risk==='蟑螂'||row.risk==='鼠类'||row.risk==='飞虫'){
                        return 'warning-row'
                    }
                }else{
                    return 'warning-row'
                }
            } 
            return ''
        },
        indexMethod(index) {
            return index * 1 +1 ;
        },
        del(index){
           //console.log(index)
           this.list.splice(index,1)
        },
        change(e){
            //console.log(e)
            if(e){
                //只显示未整改
                let list=[]
                this.list.forEach(item => {
                    if(item.risk!=="无"&&item.risk!=="--"){
                        list.push(item)
                    }
                });
                this.list=list
            }else{
                this.list=this.data.actionList
            }
        },
        getDesc(){
            let total = this.data.actionList.length,notDone=0,that=this
            this.data.actionList.forEach(item=>{
                if(item.risk!=="--"&&item.risk!=="无"){
                    notDone++
                }
                //图片地址替换
                for(let i=0;i<item.images.length;i++){
                    item.images[i]=that.API.url+'utils/http_test.php?url='+encodeURIComponent(item.images[i]+'?imageMogr2/thumbnail/!30p')
                }
                console.log(item.images)
            })
            this.list = this.data.actionList
            this.checked=true
            this.change(true)
            this.desc = `共计<font style='font-size:22px' color='#39f'>${total}</font>个全面检查地点,有<font style='font-size:22px' color='red'>${notDone}</font>个风险项。`
        }
    }
}
</script>
<style lang="less" scoped>
i{
    color:#999;
}
.el-table .warning-row {
    background: oldlace;
    color:red;
}
.risktype{
    font-size: 1.2em;
    font-weight: 'bold';
}
.el-image{
    margin-right:5px;
}
span{
    font-size: 12px;
}
.descSpan{
    font-size: 16px;
}
.ac-title{
    display: flex;
    font-size: 26px;
    color: rgb(55, 56, 56);
    font-weight: bold;
    height: 60px;
    text-align: center;
    padding:0px 0;
    border-bottom: 0.5px #f8f8f8 solid;
    .t-title{
        flex:1;
        text-align: center;
    }
    .t-right{
        width:100px;
        font-size: 10px;
        color: #999;
        .text-center{
            text-align: center;
        }
        image{
            position: absolute;
            z-index: 1;
            margin-top:-10px;
        }
    }
}
.desc{
    line-height: 30px;
    padding:10px 0;
}
.first{
    color:#666;
}
.sec{
    color:#999;
}
span.done{
    font-size: 30px;
}
.el-icon-success{
    color:rgb(59, 196, 78);
}
.el-icon-error{
    color:rgb(228, 88, 88);
}
.workinfo{
    position: relative;
}
.imgCode{
    position:absolute;
    right:10px;
    z-index: 1;
    top:0px;
}
</style>