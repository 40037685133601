<template>
    <div class="ad-list" v-loading="loading">
        <div class="workinfo">
            <div class="title">
                {{item.client}}
            </div>
            <div class="desc">
                <span v-html="desc" class="descSpan"></span>
            </div>
        </div>
        <el-table
            border
            fit
            width="100%"
            :data="list">
                <el-table-column
                    label="物料">
                    <template slot-scope="scope">
                        <span class="first">{{scope.row.material}} {{scope.row.usage+scope.row.unit}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="usageType"
                    label="使用方式">
                </el-table-column>
                <el-table-column
                    prop="proportioning"
                    label="稀释比例">
                </el-table-column>
                <el-table-column
                    prop="target"
                    label="靶标害虫">
                </el-table-column>
                <el-table-column
                    label="使用地点">
                    <template slot-scope="scope">
                        <span class="first">{{scope.row.place}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    width="220px"
                    label="图片附件">
                    <template slot-scope="scope">
                        <!-- {{scope.row.images}} -->
                        <div class="flex">
                            <Images
                            v-for="item in scope.row.images"
                            :key="item"
                            :preview-src-list="scope.row.images"
                            style="width: 100px; height: 100px"
                            :src="item"
                            @load="load"
                            @error="load"
                            fit="fit"></Images>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                width="50px"
                    label="操作">
                    <template slot-scope="scope">
                        <el-link type="info" @click="del(scope.$index)"><i class="el-icon-delete" /></el-link>
                    </template>
                </el-table-column>
        </el-table>
    </div>
</template>
<script>
import {resetJsonBy} from "@/utils/util.js"
import {accAdd} from "@/utils/caculate.js"
import Images from '@/components/Image.vue'
export default {
    name:"LastAdviseList",
    components:{
        Images
    },
    props:{
        data:{
            type:Object
        },
        download:{
            type:Boolean
        }
    },
    watch:{
        download:{
            handler(val){
                //console.log(val)
                if(val){
                    this.turnImg()
                }
            }
        },
        data:{
            handler(val){
                this.item=val
                this.list=val.moreAction
                //console.log(this.list)
                this.getDesc()
            },
            deep:true,
            immediate:true
        }
    },
    data(){
        return{
            loading:false,
            readyCount:0,
            totalImages:[],
            checked:false,
            item:{},
            list:[],
            desc:"",//行动描述
        }
    },methods:{
        load(){
            this.readyCount++
            //console.log(this.readyCount,this.totalImages.length)
            if(this.download&&this.readyCount>=(this.totalImages.length-1)&&this.totalImages.length>=this.readyCount){
                //触发下载事件
                this.loading=false
                this.$emit("readyToDnd")
            }
        },
        //变换图片地址
        turnImg(){
            let that=this,totalImages=[]
            this.readyCount=0
            this.loading=true
            //console.log(this.list)
            this.list.forEach(item=>{
                //console.log(item)
                if(item.images){
                    let imgs=[]
                    item.images.forEach(it=>{
                        console.log(it,it.indexOf(that.API.url))
                        if(it.indexOf(that.API.url)===-1){
                            it = that.API.url+'/utils/http_test.php?url='+encodeURIComponent(it)  
                        }
                        imgs.push(it)
                        totalImages.push(it)
                    })
                    item.images=imgs
                }
                //console.log(item)
            })
            this.totalImages=totalImages
            this.$forceUpdate()
            //console.log(this.list)
        },
        del(index){
           console.log(index)
           this.list.splice(index,1)
        },
        change(){
        },
        getDesc(){
            let total = this.list.length
            this.checked=true
            this.change(true)
            let list = resetJsonBy(this.list,"material"),str='',materialList=[]
            console.log(list,materialList,str)
            list.forEach(item => {
                str+=item.material+":"
                let usage=0,unit=""
                item.data.forEach(it=>{
                    unit=it.unit
                    usage=accAdd(usage,it.usage*1)
                })
                str+=usage+unit+"; "
            });
            this.desc = `共计<font style='font-size:22px' color='#39f'>${total}</font>个处理措施,${str}`
        }
    }
}
</script>
<style lang="less" scoped>
.el-image{
    margin-right:5px;
}
span{
    font-size: 12px;
}
.descSpan{
    font-size: 16px;
}
.title{
    font-size: 26px;
    color: rgb(55, 56, 56);
    font-weight: bold;
    line-height: 36px;
    padding:10px 0;
}
.desc{
    line-height: 30px;
    padding:10px 0;
}
.first{
    color:#666;
}
.sec{
    color:#999;
}
span.done{
    font-size: 30px;
}
.el-icon-success{
    color:rgb(59, 196, 78);
}
.el-icon-error{
    color:rgb(228, 88, 88);
}
</style>