<template>
    <div class="ad-list">
        <div class="workinfo">
            <div class="title">
                {{item.client}}
            </div>
            <div class="desc">
                <span class="descSpan" v-html="desc"></span>
                <el-checkbox v-model="checked" @change="change">只显示未整改</el-checkbox>
            </div>
        </div>
        <el-table
            border
            :data="list">
                <el-table-column
                    label="区域">
                    <template slot-scope="scope">
                        <span class="first">{{scope.row.firstPlace}}</span> &gt; <span class="sec"> {{scope.row.secondPlace}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="风险类型">
                    <template slot-scope="scope">
                        <span class="first">{{scope.row.risk}}</span> &gt; <span class="first">{{scope.row.riskDesc}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="completedBy"
                    label="建议完成人">
                </el-table-column>
                <el-table-column
                    label="是否完成">
                    <template slot-scope="scope">
                    <span class="done">
                        <i :class="scope.row.isDone==='是'?'el-icon-success':'el-icon-error'" />
                    </span>
                    </template>
                </el-table-column>
        </el-table>
    </div>
</template>
<script>
export default {
    name:"LastAdviseList",
    props:{
        data:{
            type:Object
        }
    },
    watch:{
        data:{
            handler(val){
                this.item=val
                this.list=val.lastAdList
                console.log(this.list)
                this.getDesc()
            },
            deep:true,
            immediate:true
        }
    },
    data(){
        return{
            checked:false,
            item:{},
            list:[],
            desc:"",//行动描述
        }
    },methods:{
        change(e){
            //console.log(e)
            if(e){
                //只显示未整改
                let list=[]
                this.list.forEach(item => {
                    if(item.isDone=="否"){
                        list.push(item)
                    }
                });
                this.list=list
            }else{
                this.list=this.data.lastAdList
            }
        },
        getDesc(){
            let total = this.list.length,notDone=0
            this.list.forEach(item=>{
                if(item.isDone=="否"){
                    notDone++
                }
            })
            this.desc = `共计<font style='font-size:22px' color='#39f'>${total} </font> 个整改项,完成了 <font style='font-size:22px' color='#39f'>${total-notDone}</font>,仍有<font style='font-size:22px' color='red'>${notDone}</font>个项需要整改。`
        }
    }
}
</script>
<style lang="less" scoped>
span{
    font-size: 12px;
}
.descSpan{
    font-size: 16px;
}
.title{
    font-size: 26px;
    color: rgb(55, 56, 56);
    font-weight: bold;
    line-height: 36px;
    padding:10px 0;
}
.desc{
    line-height: 30px;
    padding:10px 0;
}
.first{
    color:#666;
}
.sec{
    color:#999;
}
span.done{
    font-size: 30px;
}
.el-icon-success{
    color:rgb(59, 196, 78);
}
.el-icon-error{
    color:rgb(228, 88, 88);
}
</style>